import React from "react";
import { Box, Container, Image } from "theme-ui";
import { Element } from "react-scroll";
import bannerDesk from "assets/banner/beast-banner-website.jpg";
const Beasts = () => {
    return (
        <Box as="section" id="beasts" sx={styles.banner}>
            <Element name="beastsBoxElement"></Element>
            <Container sx={styles.container}>
                <Box as="div" sx={styles.imageWrapper}>
                    <picture>
                        <Image
                            sx={styles.imageBanner}
                            src={bannerDesk}
                            alt="Beasts banner"
                        />
                        <Box sx={styles.positionText}>
                            <Box as="div" sx={styles.textOnBanner}>
                                ORDINAL BEASTS
                            </Box>
                        </Box>

                        <Box sx={{...styles.positionText, ...styles.positionText2}}>
                            <Box as="div" sx={{ ...styles.textOnBanner, ...styles.subtitle }}>
                                Bitcoin Digital Artifacts
                            </Box>
                        </Box>

                        <Box sx={{...styles.positionText, ...styles.positionText3}}>
                            <Box as="div" sx={{ ...styles.textOnBanner, ...styles.subtitle3 }}>
                            Get ready to meet the BEASTS collection: fancy and elegant digital artifacts who are natural enemies of the Heroes - Genesis 
PFP collection!
This is an Ordinal series of digital artifacts: they will be inscripted in the Bitcoin blockchain and they will exist forever.

Stay tuned, more details will be released pretty soon about the brand new Noku collection!
                            </Box>
                        </Box>
                        <Box sx={styles.positionButton}>
                            <Box sx={styles.menuFooter}>
                                <Box 
                                    as="a"
                                    target="_blank"
                                    // href="https://marketplace.noku.io/"
                                    sx={{ ...styles.outBtn, ...styles.disabled }}
                                >
                                    Coming soon
                                </Box>
                            </Box>
                        </Box>
                    </picture>
                </Box>
            </Container>
        </Box>
    );
};

export default Beasts;

const styles = {
    banner: {
        paddingTop: "50px",
    },
    imageWrapper: {
        position: "relative",
    },
    imageBanner: {
        width: "100%",
        height: ["856px", "700px", "100%"],
        objectFit: "cover",
        position: "relative",
        borderRadius: "15px",
        "@media (max-width: 799px)": {
            height: "700px",
        },
        "@media (max-width: 499px)": {
            height: "800px",
        },
        "@media (max-width: 399px)": {
            height: "900px",
        }
    },
    container: {
        position: "relative",
        ".bannerIcon": {
            position: "absolute",
            display: ["none", null, null, null, "block"],
        },
    },
    outBtn: {
        color: "#fff",
        textDecoration: "none",
        fontWeight: "bold"
    },
    disabled: {
        opacity: 0.3,
        ":hover": {
            opacity: 0.3,
        },
    },
    positionText: {
        position: "absolute",
        left: "111px",
        right: "111px",
        textAlign: "center",
        justifyContent: ["center", "right"],
        display: "flex",
        top: ["15%"],
        "@media (max-width: 799px)": {
            left: 0,
            right: 0,
            justifyContent: "center",
            top: ["10%"],
        }
    },
    positionText2: {
        top: ["25%"],
        "@media (max-width: 799px)": {
            left: 0,
            right: 0,
            justifyContent: "center",
            top: ["22%"],
        }
    },
    positionText3: {
        top: ["35%"],
        "@media (max-width: 799px)": {
            left: 0,
            right: 0,
            justifyContent: "center",
            top: ["35%"],
        },
        "@media (max-width: 399px)": {
            top: ["30%"],
        }
    },
    subtitle: {
        fontSize: ["3vw"],
        "@media (min-width: 1599px)": {
            fontSize: "40px",
            lineHeight: ["1.6" ],
            width: ["600px"],
        },
        "@media (max-width: 799px)": {
            fontSize: "20px",
            lineHeight: ["1.6" ],
            width: "auto",
            maxWidth: "80%",
            justifyContent: "center",
            padding: "10px",
            borderRadius: "10px",
            backgroundColor: "rgba(0, 0, 0, 0.7)",

        }
    },
    subtitle3: {
        fontSize: ["1.7vw"],
        lineHeight: ["1.6" ],
        "@media (min-width: 1599px)": {
            fontSize: "30px",
            lineHeight: ["1.6" ],
            width: ["600px"],
        },
        "@media (max-width: 799px)": {
            fontSize: "20px",
            lineHeight: ["1.6" ],
            width: "auto",
            maxWidth: "80%",
            left: 0,
            right: 0,
            justifyContent: "center",
            padding: "10px",
            borderRadius: "10px",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
        }
    },
    textOnBanner: {        
        width: ["40vw"],
        lineHeight: ["1.26", "1.26", "1.1" ],
        fontSize: ["4vw"],
        color: "#fff",
        fontWeight: "900",
        textAlign: ["center", "left"],
        "@media (min-width: 1599px)": {
            fontSize: "70px",
            lineHeight: ["1.6" ],
            width: ["600px"],
        },
        "@media (max-width: 799px)": {
            fontSize: "20px",
            lineHeight: ["1.6" ],
            width: "auto",
            maxWidth: "80%",
            left: 0,
            right: 0,
            justifyContent: "center",
            padding: "10px",
            borderRadius: "10px",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
        }
    },
    positionButton: {
        position: "absolute",
        left: "230px",
        right: "230px",
        textAlign: "center",
        justifyContent: ["center", "right"],
        display: "flex",
        top: ["80%"],
        "@media (max-width: 799px)": {
            top: ["83%"],
            left: 0,
            right: 0,
            justifyContent: "center",
        },
        "@media (max-width: 399px)": {
            top: ["88%"],
        }
    },
    menuFooter: {        
        background: "#4446ed",
        color: "#fff",
        borderRadius: "10px",
        width: ["291px"],
        height: ["81px"],
        flexDirection: "column",
        fontSize: ["30px"],
        fontWeight: "normal",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        "@media (max-width: 1299px)": {
            width: ["200px"],
            height: ["71px"],
            fontSize: ["25px"],
        },
        "@media (max-width: 800px)": {
            width: ["150px"],
            height: ["51px"],
            fontSize: ["20px"],
        }
    },    
};
