/** @jsx jsx */
import { jsx, Card } from "theme-ui";

export default function ColoredCard({ children, style }) {
    return (
        <Card sx={styles.cardWrapper} style={style}>
            {children}
        </Card>
    );
}

const styles = {
    cardWrapper: {
        display: "flex",
        boxShadow: "rgba(0 0 0 / 20%) 0 0 40px",
        borderWidth: "1px",
        borderRadius: "0.5rem",
        alignItems: "center",
        width: "100%",
        pt: "12px",
        pb: "12px",
        backgroundColor: "#ffc739",
        color: "black",
        marginBottom: "15px",
        paddingRight: "10px",
        paddingLeft: "10px",
    },
};
