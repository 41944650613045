import React from "react";
import { Box, Text, Container } from "theme-ui";
import { Link } from "../link";
import Logo from "../logo";
import logoLight from "assets/logos/white.svg";
import {
    FaTelegramPlane,
    FaTwitter,
    FaLinkedinIn,
    FaMediumM,
    FaInstagram,
    FaDiscord,
    FaYoutube,
} from "react-icons/fa";

export default function Footer() {
    return (
        <Box as="footer" sx={styles.footer} id="social">
            <Container sx={styles.container}>
                <Box as="div" sx={styles.iconsRow}>
                    <Link
                        href="https://twitter.com/nokuofficial"
                        target="_blank"
                        sx={styles.iconContainer}
                    >
                        <Text as="div" sx={styles.iconText}>
                            Twitter
                        </Text>
                        <Box as="div" sx={styles.icon}>
                            <FaTwitter />
                        </Box>
                    </Link>
                    <Link
                        href="https://www.instagram.com/noku.io"
                        target="_blank"
                        sx={styles.iconContainer}
                    >
                        <Text as="div" sx={styles.iconText}>
                            Instagram
                        </Text>
                        <Box as="div" sx={styles.icon}>
                            <FaInstagram />
                        </Box>
                    </Link>
                    <Link
                        href="https://discord.gg/mM8qypcCyK"
                        target="_blank"
                        sx={styles.iconContainer}
                    >
                        <Text as="div" sx={styles.iconText}>
                            Discord
                        </Text>
                        <Box as="div" sx={styles.icon}>
                            <FaDiscord />
                        </Box>
                    </Link>
                    <Link
                        href="https://t.me/nokuofficial"
                        target="_blank"
                        sx={styles.iconContainer}
                    >
                        <Text as="div" sx={styles.iconText}>
                            Telegram
                        </Text>
                        <Box as="div" sx={styles.icon}>
                            <FaTelegramPlane />
                        </Box>
                    </Link>
                    <Link
                        href="https://www.youtube.com/channel/UC2Ru2OagHzkuETQLDvy4FuA/videos"
                        target="_blank"
                        sx={styles.iconContainer}
                    >
                        <Text as="div" sx={styles.iconText}>
                            Youtube
                        </Text>
                        <Box as="div" sx={styles.icon}>
                            <FaYoutube />
                        </Box>
                    </Link>
                    <Link
                        href="https://nokuteam.medium.com/"
                        target="_blank"
                        sx={styles.iconContainer}
                    >
                        <Text as="div" sx={styles.iconText}>
                            Medium
                        </Text>
                        <Box as="div" sx={styles.icon}>
                            <FaMediumM />
                        </Box>
                    </Link>
                    <Link
                        href="https://www.linkedin.com/company/noku-io"
                        target="_blank"
                        sx={styles.iconContainer}
                    >
                        <Text as="div" sx={styles.iconText}>
                            Linkedin
                        </Text>
                        <Box as="div" sx={styles.icon}>
                            <FaLinkedinIn />
                        </Box>
                    </Link>
                </Box>
                <Box as="div" sx={styles.row}>
                    <Box as="div" sx={{ alignSelf: "center" }}>
                        <Logo image={logoLight} width="70x" />
                    </Box>
                    <Box as="div" sx={{ alignSelf: "center" }}>
                        <Text
                            as="div"
                            sx={{
                                color: "#ffffff",
                                opacity: "0.7",
                                fontSize: "14px",
                                mt: [null, null, null, null, null, "0"],
                            }}
                        >
                            Copyright by Noku -{" "}
                            <a style={{ color: "#fff" }} href="/privacy-policy">
                                Terms & Conditions - Privacy Policy
                            </a>
                        </Text>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}

const styles = {
    row: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        flexDirection: "column",
    },
    iconsRow: {
        display: "flex",
        width: "100%",
        justifyContent: "space-evenly",
        mt: "15px",
        mb: "32px",
        pb: "50px",
        flexWrap: "wrap"
    },
    iconContainer: {
        color: "#FFFFFF",
        cursor: "pointer",
        textDecoration: "none",
        fontSize: "15px",
        width: "60px",
        margin: "0 20px"
    },
    icon: {
        fontSize: "48px",
        textAlign: "center",
    },
    iconText: {
        textAlign: "center",
    },
    footer: {
        py: ["40px", null, null, null, null, "30px", "40px"],
        position: "relative",
        "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        },
    },
    container: {
        display: "flex",
        flexDirection: ["column", null, null, null, null, "row"],
        justifyContent: ["center", null, null, null, null, "space-between"],
        alignItems: "center",
        position: "relative",
        flexWrap: "wrap",
    },
    linksWrap: {
        mt: ["15px", null, null, null, null, "0"],
        display: "flex",
        flexWrap: "wrap",
        ml: "auto",
        a: {
            fontSize: ["14px", null, null, null, "16px"],
            color: "#ffffff",
            transition: "all 500ms ease",
            "&:hover": { opacity: 0.7 },
        },
        "a+a": { ml: ["15px", null, null, null, "35px"] },
    },
};
