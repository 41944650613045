import React from "react";
import { Box, Container } from "theme-ui";
import Title from "../../components/title";
import ImageCard from "../../components/imageCard";
import bilaxy from "assets/exchange/bilaxy-min.png";
import pancakeSwap from "assets/exchange/pancake-swap-min.png";

const Exchange = () => {
    return (
        <Box as="section" id="exchange" sx={styles.banner}>
            <Container>
                <Title
                    text="Exchanges"
                />
                <Box as="div" sx={styles.cardsWrapper}>
                    <ImageCard
                        src={bilaxy}
                        href="https://bilaxy.com/trade/NOKU_USDT"
                        style={{ marginBottom: "10px" }}
                    />
                    <ImageCard
                        src={pancakeSwap}
                        href="https://pancakeswap.finance/swap?exactField=output&exactAmount=100&outputCurrency=0xfB4D42BEd5618fb1a377DDB64EB56B92a6d117f2&inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&theme=light&lng=en"
                        style={{ marginBottom: "10px" }}
                    />
                </Box>
            </Container>
        </Box>
    );
};

export default Exchange;

const styles = {
    cardsWrapper: {
        display: "flex",
        justifyContent: "space-evenly",
        flexDirection: [null, "column", "row", null, null],
        alignItems: "center",
        flexWrap: "wrap",
    },
    banner: {
        overflow: "hidden",
        textAlign: "center",
        pt: "50px",
        pb: "50px",
    },
};
