import React from "react";
import { Box, Container } from "theme-ui";
import Title from "../../components/title";
import NokuGameCard from "../../components/nokuGameCard";
import urlGame1 from "assets/nokuGames/game-1.jpg";
import urlGame3 from "assets/nokuGames/game-3.jpg";
import urlGame4 from "assets/nokuGames/game-4.jpg";
const NokuGames = () => {
    return (
        <Box as="section" id="noku-games" sx={styles.banner}>
            <Container>
                <Title text="Noku Games" />
                <Box as="div" sx={styles.rowNoku}>
                    <NokuGameCard
                        title="Crypto Heroes"
                        description="NFTs Trading Game"
                        imageUrl={urlGame1}
                        link="https://cryptoheroes.noku.io/app/"
                    />
                    <NokuGameCard
                        title="PREDICTION GAME"
                        description="NFTs Trading Game"
                        imageUrl={urlGame3}
                        link="https://cryptoheroes.noku.io/app/prediction-game-deck-selection"
                    />
                    <NokuGameCard
                        title="WORLD SUPER LEAGUE"
                        description="Fantasy football game"
                        imageUrl={urlGame4}
                        link="https://thesuperleague.world/it/"
                    />
                    {
                        <NokuGameCard 
                            title="New NFT games"
                            description="Coming soon" 
                        />
                    }
                </Box>
            </Container>
        </Box>
    );
};

export default NokuGames;

const styles = {
    banner: {
        overflow: "hidden",
        textAlign: "center",
        pt: "50px",
        mb: "112px",
        "@media (max-width: 767px)": {
            pt: "20px",
        },
    },
    rowNoku: {
        alignSelf: "center",
        display: ["inline-block", "inline-block", "inline-block", "inline-block", "flex"],
        width: "100%",
        overflowX: "scroll",
        overflow: "auto",
        whiteSpace: "nowrap",
        justifyContent: "space-between",
        "::-webkit-scrollbar": { /* Hide scrollbar for Chrome, Safari and Opera */
            display: "none",
        },
        /* Hide scrollbar for IE, Edge and Firefox */
        "msOverflowStyle": "none" /* IE and Edge */,
        scrollbarWidth: "none" /* Firefox */,
    },
};
