/** @jsx jsx */
import { jsx, Card, Image, Link } from "theme-ui";

export default function ImageCard({ src, href, style }) {
    return (
        <Card sx={styles.card} style={style}>
            <Link target="_blank" href={href} style={styles.imageLink}>
                <Image src={src} style={styles.img}></Image>
            </Link>
        </Card>
    );
}

const styles = {
    card: {
        width: ["10rem", "13rem"],
        px: "8px",
        boxShadow:
            "0 0 #0000,0 0 #0000, 0 1px 3px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.06)",
        borderRadius: "0.5rem",
        justifyContent: "center",
        alignItems: "center",
        height: "60px",
        display: "flex",
        borderColor: "rgba(229,231,235,1)",
        cursor: "pointer",
        backgroundColor: "white",
    },
    img: {
        maxHeight: "2.4em"
    },
    imageLink: {
        maxWidth: "80%",
        maxHeight: "80%",
        display: "flex"
    },
};
