/** @jsx jsx */
import { jsx, Card } from "theme-ui";

export default function TransparentCard({ children, style }) {
    return (
        <Card path="/" sx={styles.cardWrapper} style={style}>
            {children}
        </Card>
    );
}

const styles = {
    cardWrapper: {
        boxShadow: "rgba(0 0 0 / 20%) 0 0 40px",
        color: "white",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
        "@media (min-width: 960px)": {
            justifyContent: "space-between",
            alignItems: "center",
            pt: "30px",
            pb: "30px",
            pl: "86px",
            pr: "32px",
        },
        pt: ["20px", "20px", "30px"],
        pb: "30px",
        pl: "16px",
        pr: "16px",
    },
};
