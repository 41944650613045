import React from "react";
import { Box, Container } from "theme-ui";
import Title from "../../components/title";
import NokuWithNumber from "../../components/nokuWithNumber";
const NokuNumbers = () => {
    return (
        <Box as="section" id="noku-numbers" sx={styles.banner}>
            <Container sx={styles.container}>
                <Title text="Noku Statistics" />
                <Box as="div" sx={styles.rowNoku}>
                    <NokuWithNumber number="84,500" label="Noku Ecosystem Users" />
                    <NokuWithNumber number="13,000" label="Daily Active players" />
                    <NokuWithNumber number="71,000" label="Marketplace products" />
                </Box>
            </Container>
        </Box>
    );
};

export default NokuNumbers;

const styles = {
    container: {
        maxWidth: "1706px",
        px: "0"
    },
    cardsWrapper: {
        display: "flex",
        justifyContent: "space-evenly",
        flexDirection: [null, "column", "row", null, null],
        alignItems: "center",
        flexWrap: "wrap",
    },
    banner: {
        overflow: "hidden",
        textAlign: "center",
        pt: ["20px", null, null, null, "50px"],
    },
    rowNoku: {
        display: "flex",
        flexDirection: ["column", null, null, null, "row"],
        marginTop: ["30px", "30px", "0px"],
    },
};
