import React from "react";
import { Box, Image, Text } from "theme-ui";
import nokuIcon from "assets/nokuNumbers/nokuIcon.svg";

export default function NokuWithNumber({ number, label }) {
    return (
        <Box as="div" style={styles.wrapper}>
            <Image src={nokuIcon} />
            <Box
                as="div"
                style={{ position: "absolute" }}
                sx={styles.circleInfo}
            >
                <Text as="div" sx={styles.circleNumber}>
                    {number}
                </Text>
                <Text as="div" sx={styles.circleTitle}>
                    {label}
                </Text>
            </Box>
        </Box>
    );
}

const styles = {
    wrapper: { 
        position: "relative", 
        width: "100%", 
        height: "100%",
        lineHeight: 1
    },
    circleInfo: {
        position: "absolute",
        top: "51%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    circleNumber: {
        margin: "0 0 23px",
        textShadow: "0px 1px 100px #000",
        fontFamily: "Lato",
        fontSize: ["52px", "52px", "52px", "52px", "52px", "72px", "95.5px"],
        fontWeight: "900",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "0.92",
        letterSpacing: "normal",
        textAlign: "center",
        color: "#fff",
    },
    circleTitle: {
        color: "white",
        fontSize: ["13.5px", "13.5px", "25px"],
        fontWeight: "900",
        lineHeight: 1,
        letterSpacing: "0.5px",
    },
};
