import React from "react";
import { Box, Container } from "theme-ui";
import Title from "../../components/title";
import PersonCard from "../../components/personCard";
import roberto from "assets/team/t-roberto.jpg";
import manuel from "assets/team/t-manuel.jpg";
import gianluca from "assets/team/t-gianluca.jpg";
import alan from "assets/team/t-alan.jpg";
import diego from "assets/team/t-diego.jpg";
import gianpietro from "assets/team/t-gianpietro.jpg";
import michelangelo from "assets/team/t-michele.jpg";
import luca from "assets/team/t-luca.jpeg";
import lorenzo from "assets/team/t-lorenzo.jpeg";
import marco from "assets/team/t-marco.jpg";
import bea from "assets/team/t-bea.jpg";
import cristina from "assets/team/t-cristina.jpg";
import damiano from "assets/team/t-damiano.jpeg";
import matteo from "assets/team/t-matteo.jpeg";
import moon from "assets/team/t-moon.jpg";
export default function FindUs() {
    return (
        <Box as="section" id="team" sx={styles.banner}>
            <Container>
                <Title text="Core team" />
                <Box as="div" sx={styles.personWrapper}>
                    <PersonCard
                        avatar={roberto}
                        name="Roberto Gorini"
                        position="CEO & Founder"
                        linkedin="https://www.linkedin.com/in/roberto-gorini-b96b45153/"
                        twitter="https://www.twitter.com/"
                    />
                    <PersonCard
                        avatar={manuel}
                        name="Manuel Olivi"
                        position="COO"
                        linkedin="https://www.linkedin.com/in/manuelolivi/"
                        twitter="https://www.twitter.com/"
                    />
                    <PersonCard
                        avatar={gianluca}
                        name="Gianluca Perletti"
                        position="CTO"
                        linkedin="https://www.linkedin.com/in/gianluca-perletti-1b805475/"
                        twitter="https://www.twitter.com/"
                    />
                    <PersonCard
                        avatar={alan}
                        name="Alan Scarpellini"
                        position="Lead Full-Stack Developer"
                        linkedin="https://www.linkedin.com/in/alanscarpellini/"
                        twitter="https://www.twitter.com/"
                    />
                    <PersonCard
                        avatar={diego}
                        name="Diego Imbriani"
                        position="Software Architect"
                        linkedin="https://www.linkedin.com/in/diegoimbriani/"
                        twitter="https://www.twitter.com/"
                    />
                    <PersonCard
                        avatar={gianpietro}
                        name="Gian Pietro Secomandi"
                        position="UX/UI & Graphics"
                        linkedin="https://www.linkedin.com/in/gianpietrosecomandi/"
                        twitter="https://www.twitter.com/"
                    />
                    <PersonCard
                        avatar={luca}
                        name="Luca Valsecchi"
                        position="Developer"
                        linkedin="https://www.linkedin.com/in/luca-valsecchi-b57a53137/"
                        twitter="https://www.twitter.com/"
                    />
                    <PersonCard
                        avatar={lorenzo}
                        name="Lorenzo Tosetti"
                        position="Developer"
                        linkedin="https://www.linkedin.com/in/lorenzo-tosetti/"
                        twitter="https://www.twitter.com/"
                    />
                    <PersonCard
                        avatar={marco}
                        name="Marco Guarducci"
                        position="Researcher"
                        linkedin="https://www.linkedin.com/in/marco-guarducci-759429142/"
                        twitter="https://www.twitter.com/"
                    />
                    <PersonCard
                        avatar={michelangelo}
                        name="Michelangelo Riccobene"
                        position="Senior Solidity Developer"
                        linkedin="https://www.linkedin.com/in/michelangeloriccobene/"
                        twitter="https://www.twitter.com/"
                    />
                    <PersonCard
                        avatar={bea}
                        name="Beatrice Simioni"
                        position="Office Manager"
                        linkedin="https://www.linkedin.com/in/beatrice-simioni-4862a8154/"
                        twitter="https://www.twitter.com/"
                    />
                    <PersonCard
                        avatar={cristina}
                        name="Cristina Ricchi"
                        position="Administration Manager"
                        linkedin="https://www.linkedin.com/in/alanscarpellini/"
                        twitter="https://www.twitter.com/"
                    />
                    <PersonCard
                        avatar={damiano}
                        name="Damiano Mareri"
                        position="Marketing & Relationship Manager"
                        linkedin="https://www.linkedin.com/in/damiano-mareri-75444ba2/"
                        twitter="https://www.twitter.com/"
                    />
                    <PersonCard
                        avatar={matteo}
                        name="Matteo Pagani"
                        position="NFT & DeFi Consultant"
                        linkedin="https://www.linkedin.com/in/matteo-pagani-275aa612a/"
                        twitter="https://www.twitter.com/"
                    />
                    <PersonCard avatar={moon} name="Moon" position="Mascotte" />
                </Box>
            </Container>
        </Box>
    );
}

const styles = {
    cardsWrapper: {
        display: "flex",
        justifyContent: "space-evenly",
        mb: "50px",
    },
    banner: {
        overflow: "hidden",
        textAlign: "center",
        pt: ["20px", null, null, null, "50px"],
        pb: ["20px", null, null, null, "50px"],
    },
    personWrapper: {
        display: "flex",
        flexDirection: [null, null, null, "row", null],
        alignItems: "center",
        flexWrap: "wrap",
        justifyContent: ["space-around", null, null, null, null, "space-between"],
    },
};
