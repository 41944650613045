import React from "react";
import Slider from "react-slick";
import { Box, Image, Text, Container } from "theme-ui";
import { scroller } from "react-scroll";
import sticker from "assets/carousel/stickers-sold-out.png";
import slider1 from "assets/carousel/pre-mint-desktop.jpg";
import slider1mob from "assets/carousel/pre-mint-mobile.jpg";
import heroesLogo from "assets/carousel/logo-crypto-heroes.svg";
// import cryppoLogo from "assets/carousel/logo-cryppo.png";
import wslLogo from "assets/carousel/logo-wsl.svg";
// import chLogo from "assets/carousel/logo-ch.svg";
import beastsImgText from "assets/carousel/beasts-slider-text.png";
import beastsImg from "assets/carousel/beasts-slider.jpg";
import beastsImgMobile from "assets/carousel/beasts-slider-mobile.jpg";
// import slider2 from "assets/carousel/img-slider-2.jpg";
// import slider2mob from "assets/carousel/img-slider-2-mobile.jpg";
import slider3 from "assets/carousel/img-slider-3.jpg";
import slider3mob from "assets/carousel/img-slider-3-mobile.jpg";
import chMobileDesc from "assets/carousel/ch-mobile-description.png";
import sliderCh from "assets/carousel/banner-ch-card-x.jpg";
import sliderChmob from "assets/carousel/banner-ch-card-x-mobile.jpg";
// import slider4 from "assets/carousel/img-slider-4.jpg";
// import slider4mob from "assets/carousel/img-slider-4-mobile.jpg";
import slideWsl from "assets/carousel/slide-wsl.jpg";
import slideWslMob from "assets/carousel/slide-wsl-mobile.jpg";
import logoWhite from "assets/logos/white.svg";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";

const minHeightSlide = 600;


const styles = {
	br: {
		display: ["none", "none", "none", "block"],
	},
	nokuLogo: {
		width: 160,
		marginTop: 20,
		display: ["block", "block", "block", "none !important"],
	},
	slideWslText: {
		display: ["block", null, null, "none"],
		color: "#fff",
		// margin: 20,
		// fontSize: 21.5,
		fontWeight: 900,
		fontSize: "5vw",
		margin: "30px 50px",
		textAlign: "center",
		lineHeight: "1.5",
	},
	disabled: {
		opacity: 0.3,
		":hover": {
			opacity: 0.3,
		},
	},
	marginTextDate: {
		"@media (max-width: 767px)": {
			marginTop: "10px",
			display: "block",
		},
	},
	textDate: {
		color: "#2ee7df",
		fontWeight: "900",
		"@media (max-width: 767px)": {
			marginTop: "10px",
		},
	},
	textDateBeasts: {
		color: "#2ee7df",
		fontWeight: "900"
	},
	textSubTitleWsl: {
		color: "white",
		position: ["static", "static", "static", "absolute"],
		fontSize: "1.5vw",
		width: "90%",
		lineHeight: "1.1",
		top: ["69%", "69%", "72%", "60%"],
		left: ["50%", "50%", "50%", "6%"],
		minHeight: "86px",
		"@media (max-width: 767px)": {
			fontSize: "21.5px",
			textAlign: "center",
			mb: "20px",
			alignItems: "center",
			display: "flex",
			justifyContent: "center",
			flexDirection: "column",
		},
		"@media (min-width: 1500px)": {
			fontSize: "24px",
		},
	},
	textTitle: {
		color: "white",
		fontWeight: "900",
		position: ["static", "static", "static", "absolute"],
		fontSize: "5vw",
		width: ["50%", "50%", "90%", "90%"],
		lineHeight: "1.1",
		top: ["69%", "69%", "69%", "37%"],
		left: ["50%", "50%", "50%", "6%"],
		minHeight: "86px",
		"@media (max-width: 767px)": {
			fontSize: "21.5px",
			textAlign: "center",
			mb: "20px",
			alignItems: "center",
			display: "flex",
			justifyContent: "center",
		},
		"@media (min-width: 1500px)": {
			fontSize: "70px",
		},
	},
	textTitleCh: {
		top: ["69%", "69%", "69%", "37%"],
		width: "90%",
		"@media (max-width: 767px)": {
			fontSize: "21.5px",
			textAlign: "center",
			mb: "0px",
			alignItems: "center",
			display: "flex",
			justifyContent: "center",
		},
	},
	textTitleBeasts: {
		top: ["69%", "69%", "69%", "27%"],
		width: "90%",
		"@media (max-width: 767px)": {
			fontSize: "21.5px",
			textAlign: "center",
			mb: "0px",
			alignItems: "center",
			display: "flex",
			justifyContent: "center",
		},
	},
	textTitleSmall: {
		fontSize: ["24px", null, null, "2vw", "2.3vw"],
		"@media (max-width: 767px)": {
			fontSize: "18.5px",
			textAlign: "center",
			mb: "20px",
		},
		width: ["90%", "90%", "46%"],

		span: {
			fontSize: ["16px", null, null, "1.5vw"],
			display: "block",
			mt: "20px",
		},
	},
	textSubTitle: {
		color: "white",
		fontWeight: "900",
		position: ["static", "static", "static", "absolute"],
		fontSize: "3vw",
		width: ["50%", "50%", "90%", "90%"],
		lineHeight: "1.1",
		top: ["69%", "69%", "69%", "53%"],
		left: ["50%", "50%", "50%", "6%"],
		minHeight: "86px",
		"@media (max-width: 767px)": {
			textAlign: "center",
			mb: "20px",
			alignItems: "center",
			display: "flex",
			justifyContent: "center",
			fontSize: "20px",
		},
		"@media (min-width: 1500px)": {
			fontSize: "40px",
		},
	},
	largeYellow: {
		fontWeight: "900",
		position: ["static", "static", "static", "absolute"],
		width: ["50%", "50%", "90%", "90%"],
		lineHeight: "1.1",
		top: ["69%", "69%", "69%", "37%"],
		left: ["50%", "50%", "50%", "6%"],
		minHeight: "86px",
		color: "#ffc739",
		fontSize: ["20vw", null, "8vw", null, null, null, "125px"],
		"@media (max-width: 767px)": {
			fontSize: "10vw",
			textAlign: "center",
			mb: "20px",
		},
		"@media (min-width: 1680px)": {
			fontSize: "70px",
		},
		span: {
			fontSize: ["22px", null, null, "3vw", null, null, "44px"],
			display: "block",
			mt: "0",
			color: "#fff",
		},
	},
	sticker: {
		position: "absolute",
		top: ["36%", "45%", "45%", "-40px", "-50px"],
		right: [null, null, null],
		left: ["50%", null, null, "36vw", null, null, "590px"],
		width: ["100px", null, null, "70px", "8vw", null, "150px"],
		marginLeft: ["-50px", null, null, 0, 0, 0],
	},
	bannerButtonNoku: {
		position: ["static", "static", "static", "absolute"],
		bottom: ["6%", "6%", "6%", "20%"],
		left: ["50%", "50%", "50%", "6%"],
		backgroundColor: "#ffc739",
		width: ["160px", null, null, null, "200px"],
		lineHeight: ["45px", null, null, null, "60px"],
		height: ["45px", null, null, null, "60px"],
		fontSize: ["16px", null, null, null, "22px"],
		textDecoration: "none",
		display: ["inline-block", null, null, "none"],
		color: "#000",
		textAlign: "center",
		fontWeight: "bold",
		borderRadius: "4px",
		transition: "all .25s",
		":hover": {
			opacity: 0.75,
		},
		"@media (max-width: 767px)": {
			mb: "35px",
		},
	},
	bannerButtonBeasts: {
		cursor: "pointer",
	},
	bannerButton: {
		position: ["static", "static", "static", "absolute"],
		bottom: ["6%", "6%", "6%", "20%"],
		left: ["50%", "50%", "50%", "6%"],
		backgroundColor: "#ffc739",
		width: ["160px", null, null, null, "200px"],
		lineHeight: ["45px", null, null, null, "60px"],
		height: ["45px", null, null, null, "60px"],
		fontSize: ["16px", null, null, null, "22px"],
		textDecoration: "none",
		display: "inline-block",
		color: "#000",
		textAlign: "center",
		fontWeight: "bold",
		borderRadius: "4px",
		transition: "all .25s",
		":hover": {
			opacity: 0.75,
		},
		"@media (max-width: 767px)": {
			mb: "35px",
		},
	},
	chBannerButton: {
		position: ["static", "static", "static", "absolute"],
		bottom: ["6%", "6%", "6%", "17%"],
		left: "calc(50% - 100px)",
		backgroundColor: "#ffc739",
		width: "200px",
		lineHeight: ["45px", null, null, null, "60px"],
		height: ["45px", null, null, null, "60px"],
		fontSize: ["16px", null, null, null, "22px"],
		textDecoration: "none",
		display: "inline-block",
		color: "#000",
		textAlign: "center",
		fontWeight: "bold",
		borderRadius: "4px",
		transition: "all .25s",
		":hover": {
			opacity: 0.75,
		},
		"@media (max-width: 767px)": {
			mb: "35px",
			mt: "35px",
		},
	},
	itemWrapper: {
		minHeight: minHeightSlide + "px",
		borderRadius: "15px",
		overflow: "hidden",
		position: "relative",
		"@media (max-width: 767px)": {
			backgroundColor: "#1d1d1d",
			display: "flex !important",
			flexDirection: "column",
			alignItems: "center",
		},
	},
	logo: {
		position: ["static", "static", "static", "absolute"],
		top: ["50%", "50%", "50%", "0%"],
		left: ["50%", "50%", "50%", "6%"],
		width: ["213px", "213px", "213px", "30%"],
		"@media (max-width: 767px)": {
			zIndex: 10,
			mb: "11px",
			mt: "25px",
			height: "76px",
		},
	},
	logoHero: {
		"@media (max-width: 767px)": {
			mt: "0",
			height: "120px",
		},
	},
	logoWsl: {
		top: ["50%", "50%", "50%", "8%"],
	},
	logoBeasts: {
		top: ["50%", "50%", "50%", "8%"],
		left: ["50%", "50%", "50%", "5%"],
		width: ["213px", "213px", "213px", "50%"],
		"@media (max-width: 767px)": {
			height: "auto",
			marginTop: "25px",
		},
	},
	logoCh: {
		top: ["50%", "50%", "50%", "7%"],
		width: ["213px", "213px", "213px", "18%"],
	},
	chMobileDesc: {
		height: "auto !important",
		"@media (min-width: 768px)": {
			display: "none !important",
		},
	},
	imageMob: {
		width: "100%",
		display: "none !important",
		"@media (max-width: 767px)": {
			display: "block !important",
		},
	},
	imageDes: {
		width: "100%",
		minHeight: minHeightSlide + "px",
		maxHeight: "70vh",
		objectFit: "cover",
		"@media (max-width: 767px)": {
			display: "none !important",
		},
	},
	carousel: {
		px: ["10px", null, null, 0, 0, 0],
		overflow: "hidden",
		borderRadius: 10,
		".slick-list": {
			"margin-left": "-20px",
			"margin-right": "-20px",
			"@media (max-width: 767px)": {
				overflow: "hidden",
			},
		},
		".slick-slide": {
			px: "20px",
			"@media (max-width: 767px)": {
				paddingLeft: "5px",
				paddingRight: "5px",
			},
		},
		".slick-dots": {
			position: "static !important",
		},
		".slick-dots li button:before": {
			color: "#fff",
			fontSize: "12px",
		},
		".slick-dots li.slick-active button::before": {
			color: "#fff",
			fontSize: "12px",
		},
		".slick-slider .slick-arrow": {
			width: ["80px"],
			height: ["80px"],
			py: ["25px"],
			opacity: "0.8",
			backgroundColor: "#333",
			borderRadius: "50%",
			zIndex: "1",
			color: "#dfdfdf",
			bottom: "20px",
			top: "auto",
			"@media (max-width: 767px)": {
				display: "none !important",
			},
		},
		".slick-slider .slick-arrow.slick-next": {
			right: "50%",
			"margin-right": "-150px",
		},
		".slick-slider .slick-arrow.slick-prev": {
			left: "50%",
			"margin-left": "-150px",
		},
	},
};
const settings = {
	className: "center",
	dots: true,
	infinite: true,
	speed: 500,
	slidesToScroll: 1,
	nextArrow: <FaChevronRight />,
	prevArrow: <FaChevronLeft />,
	centerMode: false,
	variableWidth: false,
	autoplay: false,
	autoplaySpeed: 4000,
	responsive: [
		{
			breakpoint: 768,
			settings: {
				centerMode: false,
				centerPadding: "0px",
				variableWidth: false,
			},
		},
	],
};


const Carousel = () => {
	return (
		<Container>
			<Box as="section" id="carousel" sx={styles.carousel}>
				<Slider {...settings}>

					<Box as="div" sx={styles.itemWrapper}>
						<Image src={sliderChmob} sx={styles.imageMob} />
						<Image
							src={chMobileDesc}
							sx={{ ...styles.logo, ...styles.logoWsl, ...styles.chMobileDesc }}
						/>
						<Box
							as="a"
							href="https://marketplace.noku.io/app/author/625ec0a416253f00121f7813"
							target="_blank"
							sx={styles.chBannerButton}
							mr={2}
						>
							Discover Now
						</Box>
						<Image src={sliderCh} sx={styles.imageDes} />
					</Box>

					<Box as="div" sx={styles.itemWrapper}>
						<Image src={beastsImgMobile} sx={styles.imageMob} />

						<Image
							src={beastsImgText}
							sx={{ ...styles.logo, ...styles.logoBeasts }}
						/>

						<Text
							as="div"
							sx={{ ...styles.textTitle, ...styles.textTitleBeasts }}
						>
							Your fancy pieces<br />of digital history!
							{/* Your fancy pieces<br />of digital history:<br />BEASTS are on<br /> their way! */}
							{/* Bitcoin <Box as="br" sx={styles.br} /> Tournament */}
						</Text>
						<Text as="div" sx={styles.textSubTitle} >
							Mint date:{" "}
							<Text as="span" sx={styles.textDateBeasts} >
								TBA
							</Text>
						</Text>

						<Box
							sx={{ ...styles.bannerButton, ...styles.bannerButtonBeasts }}
							mr={2}
							onClick={() => {
								scroller.scrollTo("beastsBoxElement", {
									duration: 500,
									smooth: true,
									offset: -140,
								})
							}}
						>
							Discover Now
						</Box>

						{/* <ScrollLink
                            sx={styles.bannerButton}
                            // className={`${styles.bannerButton}`}
                            to="beasts"
                            key={"beasts"}
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                        >
                            Discover
                        </ScrollLink> */}
						<Image src={beastsImg} sx={styles.imageDes} />
					</Box>

					<Box as="div" sx={styles.itemWrapper}>
						<Image src={slideWslMob} sx={styles.imageMob} />
						<Image src={slideWsl} sx={styles.imageDes} />
						<Image
							src={logoWhite}
							sx={styles.nokuLogo}
						/>
						<Box as="p" sx={styles.slideWslText}>
							A Web3 platform about NFTs and play-to-earn games
						</Box>
						<Box
							as="a"
							href="https://wallet.noku.io/app/dashboard"
							target="_blank"
							sx={styles.bannerButtonNoku}
							mr={2}
						>
							Join Us
						</Box>
					</Box>

					<Box as="div" sx={styles.itemWrapper}>
						<Image src={slider3mob} sx={styles.imageMob} />
						<Image
							src={wslLogo}
							sx={{ ...styles.logo, ...styles.logoWsl }}
						/>
						<Text as="div" sx={styles.textTitle}>
							Play Your Fantasy
							<br />
							Football Game
						</Text>
						<Box
							as="a"
							href="https://thesuperleague.world/it/"
							target="_blank"
							sx={styles.bannerButton}
							mr={2}
						>
							Discover Now
						</Box>
						<Image src={slider3} sx={styles.imageDes} />
					</Box>

					{/* <Box as="div" sx={styles.itemWrapper}>
                        <Image src={slider2mob} sx={styles.imageMob} />
                        <Image src={cryppoLogo} sx={styles.logo} />
                        <Text as="div" sx={styles.textTitle}>
                            Discover the new Cryppo
                        </Text>
                        <Box
                            as="a"
                            target="_blank"
                            sx={{ ...styles.bannerButton, ...styles.disabled }}
                            mr={2}
                        >
                            Coming soon
                        </Box>
                        <Image src={slider2} sx={styles.imageDes} />
                    </Box> */}

					<Box as="div" sx={styles.itemWrapper}>
						<Image src={slider1mob} sx={styles.imageMob} />
						<Image
							src={heroesLogo}
							sx={{ ...styles.logo, ...styles.logoHero }}
						/>
						<Text as="div" sx={{ ...styles.largeYellow }}>
							PRE-MINT
							<span>22/6/2022 1PM CEST</span>
							<Image src={sticker} sx={{ ...styles.sticker }} />
						</Text>
						<Box
							as="a"
							href="https://marketplace.noku.io/app/collection/heroes-genesis"
							target="_blank"
							sx={styles.bannerButton}
							mr={2}
						>
							Discover Now
						</Box>
						<Image src={slider1} sx={styles.imageDes} />
					</Box>

					{/* <Box as="div" sx={styles.itemWrapper}>
                        <Image src={slider4mob} sx={styles.imageMob} />
                        <Image
                            src={chLogo}
                            sx={{ ...styles.logo, ...styles.logoCh }}
                        />
                        <Text
                            as="div"
                            sx={{ ...styles.textTitle, ...styles.textTitleCh }}
                        >
                            Bitcoin <Box as="br" sx={styles.br} /> Tournament
                        </Text>
                        <Text as="div" sx={styles.textSubTitleWsl}>
                            First match:{" "}
                            <Text as="span" sx={styles.textDate}>
                                Tuesday 10 November
                            </Text>
                            <br />
                            Every Tue/Fri from{" "}
                            <Box as="span" sx={styles.marginTextDate}>
                                <Text as="span" sx={styles.textDate}>
                                    08/11/2022
                                </Text>{" "}
                                to{" "}
                                <Text as="span" sx={styles.textDate}>
                                    23/12/2022
                                </Text>
                            </Box>
                        </Text>
                        <Box
                            as="a"
                            href="https://cryptoheroes.noku.io/app/"
                            target="_blank"
                            sx={styles.bannerButton}
                            mr={2}
                        >
                            Subscribe
                        </Box>
                        <Image src={slider4} sx={styles.imageDes} />
                    </Box> */}
				</Slider>
			</Box>
		</Container>
	);
};

export default Carousel;
