import React from "react";
import { Box } from "theme-ui";
import { StickyProvider } from "contexts/app/app.provider";
import Seo from "components/seo";
import Layout from "components/layout";
import Banner from "sections/banner/banner";
import BannerImage from "sections/imageBanner/imageBanner";
import BannerMarketing from "sections/bannerMarketing/bannerMarketing";
import FindUs from "sections/findUs/findUs";
import Ecosystem from "../sections/ecosystem/ecosystem";
import Exchange from "../sections/exchange/exchange";
import Team from "../sections/team/team";
import MediaAboutUs from "../sections/mediaAboutUs/mediaAboutUs";
import NokuNumbers from "../sections/nokuNumbers/nokuNumbers";
import NokuGames from "../sections/nokuGames/nokuGames";
import Carousel from "../sections/carousel/carousel";
import Footer from "components/footer/footer";
import "rc-drawer/assets/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import background from "assets/bg-4-under-hand.png";
import bg3 from "assets/bg-3-left.png";
import bgBannerDesktop from "assets/bg-gradient-desktop-1.jpg";
import bgBannerMobile from "assets/bg-gradient-mobile-1.jpg";
import bgFooter from "assets/footer/footer.png";
import Beasts from "../sections/beasts/beasts";

const appendFreshdeskScript = () => {
    if(typeof window !== "undefined") {
        const freshdeskId = "77000000719";
        window.fwSettings = {
        widget_id: freshdeskId,
        };
        let n = function () {
        n.q.push(arguments);
        };
        n.q = [];
        window.FreshworksWidget = n;
    
        const script = document.createElement("script");
        script.src = `https://euc-widget.freshworks.com/widgets/${freshdeskId}.js`;
        script.async = true;
        document.body.appendChild(script);        
    }    
};

export default function IndexPage() {
    appendFreshdeskScript();
    return (
        <StickyProvider>
            <Layout>
                <Seo title="Noku" />
                <Box as="div" sx={styles.bannerBg}>
                    <Banner />
                    <Carousel />
                    <NokuGames />
                </Box>

                <BannerImage />
                <Box
                    as="div"
                    style={{ background: `url(${background}) no-repeat`, backgroundSize: "cover" }}
                >
                    <NokuNumbers />
                    <Beasts />
                    <Ecosystem />
                </Box>
                <BannerMarketing />
                <Box
                    as="div"
                    style={{
                        background: `url(${bg3}) no-repeat`,
                        backgroundPosition: "right center",
                        backgroundSize: "contain",
                    }}
                >
                    <FindUs />
                    <Exchange />
                </Box>
                <Team />
                <Box
                    as="div"
                    style={{
                        background: `url(${bgFooter})`,
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <MediaAboutUs />
                    <Footer />
                </Box>
            </Layout>
        </StickyProvider>
    );
}

const styles = {
    bannerBg: {
        backgroundImage: [
            `url(${bgBannerMobile})`,
            null,
            null,
            null,
            `url(${bgBannerDesktop})`,
        ],
        backgroundRepeat: ["no-repeat", null, null, null, "no-repeat"],
        backgroundPosition: ["top", null, null, null, "top"],
        backgroundSize: 'cover'
    },
};
