import React from "react";
import { Box, Container, Text } from "theme-ui";
import HandImage from "./circleImage";
import TokenInfo from "./tokenInfo";
import background from "assets/bg-4-under-hand.png";

const Ecosystem = () => {
    const texts = [
        {
            title: "Users",
            description:
                "Play, invest and exchange value through Noku's NFT Gaming Platforms",
        },
        {
            title: "Manage your assets in one place",
            description:
                "Manage several portfolios and resources on blockchain together, in one place.",
        },
        {
            title: "Local brands",
            description:
                "User loyalty within the Noku universe gaming platforms",
        },
        {
            title: "Crypto Investors",
            description:
                "Profit of the economy and return through the tokens of the Noku ecosystem",
        },

        {
            title: "Game Influencers",
            description:
                "Earn an additional source of income by promoting Noku's NFT gaming platforms for your community followers, through the marketplace",
        },
    ];
    return (
        <Box as="section" id="ecosystem" sx={styles.banner}>
            <Container>
                <TokenInfo />                
            </Container>
            <HandImage />
            <Container>
                <Box as="div" sx={styles.textList}>
                    {texts.map((text) => (
                        <Box
                            as="div"
                            sx={styles.textWrapper}
                            key={`ecosystem-${text.title}`}
                        >
                            <Text as="div" sx={styles.textTitle}>
                                {text.title}
                            </Text>
                            <Text as="div" sx={styles.description}>
                                {text.description}
                            </Text>
                        </Box>
                    ))}
                </Box>
            </Container>
        </Box>
    );
};

export default Ecosystem;

const styles = {
    cardsWrapper: {
        display: "flex",
        justifyContent: "space-evenly",
        mb: "50px",
    },
    text: {
        pl: ["0", "10px", "50px", "150px", "300px"],
        pr: ["0", "10px", "50px", "150px", "300px"],
        mb: "50px",
        "@media (max-width: 960px)": {
            textAlign: "left",
        },
    },
    banner: {
        overflow: "hidden",
        textAlign: "center",
        pt: ["20px", null, null, null, "50px"],
        pb: "0",
        background: `url(${background})`,
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
    },
    textWrapper: {
        lineHeight: "1.28",
        border: "none",
        borderRight: "2px solid rgba(255,255,255,0.1)",
        textAlign: "left",
        margin: "0 5px",
        pl: ["0", null, null, null, "20px"],
        pr: ["0", null, null, null, "20px"],
        pb: ["20px", null, null, null, "0"],
        pt: ["20px", null, null, null, "0"],
        "&:last-child": {
            border: ["", null, null, null, "none"],
        },
        "@media (max-width: 960px)": {
            borderBottom: "2px solid rgba(255,255,255,0.1)",
            borderRight: "none",
        },
        width: ["100%", null, null, null, "50%",  "20%"]
    },
    textTitle: {
        color: "white",
        fontWeight: "900",
        fontSize: "25px",
    },
    textList: {
        display: "flex",
        mt: "20px",
        flexDirection: ["column", null, null, null, "row"],
        "@media (max-width: 960px)": {
            mt: "18px",
        },
    },
    description: {
        color: "#666",
        fontSize: "18px",
        lineHeight: "1.56",
    },
};
