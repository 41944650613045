/** @jsx jsx */
import { jsx, Flex, Box, Image } from "theme-ui";
import TransparentCard from "components/transparentCard";
import Address from "components/address";
import roundLogo from "assets/ecosystem/noku-icon.svg";
import background from "assets/ecosystem/img-token.jpg";

const changeNetwork = async (chainId, chainName, decimals, symbol, ticker, rpcUrls, blockExplorerUrls, iconUrls) => {    
    const hexChainId = `0x${chainId.toString(16)}`;
    try {
     await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: hexChainId }],
  
     });
    } catch ({ code = null }) {
     if (code === 4902) {
      await window.ethereum.request({
       method: 'wallet_addEthereumChain',
       params: [
        {
         chainName,
         chainId: hexChainId,
         nativeCurrency: { name: ticker, decimals, symbol },
         rpcUrls,
         blockExplorerUrls,
                    iconUrls
        },
       ],
      });
     }
    }
   };

   

export default function TokenInfo() {
    const addrEthereum = "0x8167d3b1024cb51a2dd1b4d889ddf7023420796a";
    const addrBinance = "0xfb4d42bed5618fb1a377ddb64eb56b92a6d117f2";
    const addrFantom = "0xfb4d42bed5618fb1a377ddb64eb56b92a6d117f2";
    return (
        <Box as="div" sx={styles.cardWrapper} id="token">
            <TransparentCard>
                <Box as="div" sx={styles.titleCardWrapper}>
                    Token Ecosystem
                </Box>
                <Flex sx={styles.rowWrapper}>
                    <Box as="div" sx={styles.nokuText}>
                        <Box as="div" sx={styles.nokuText.title}>
                            NOKU is the native coin of Nokuchain
                        </Box>
                        <Box as="div" sx={styles.nokuText.description}>
                            These are the wrapped tokens:
                        </Box>
                    </Box>
                    <Box as="div" sx={styles.tokenInfoWrapper}>
                        <Box as="div" sx={styles.addrWrapper}>
                            <Address
                                addr={addrEthereum}
                                description="ETHEREUM - Smart Contract Address"
                                changeNetwork={changeNetwork}
                                params={{
                                    chainId: 1
                                }}
                            />
                            <Address
                                addr={addrBinance}
                                description="BINANCE SMART CHAIN - Smart Contract Address"
                                changeNetwork={changeNetwork}
                                params={{
                                    chainId: 56,
                                    chainName: "BNB Smart Chain Mainnet", 
                                    decimals: 18, 
                                    symbol: "BNB", 
                                    ticker: "BNB", 
                                    rpcUrls: ['https://bsc-dataseed1.binance.org/'], 
                                    blockExplorerUrls: ['https://bscscan.com/']
                                }}
                            />
                            <Address
                                addr={addrFantom}
                                description="FANTOM - Smart Contract Address"
                                changeNetwork={changeNetwork}
                                params={{
                                    chainId: 250,
                                    chainName: "Fantom Opera", 
                                    decimals: 18, 
                                    symbol: "FTM", 
                                    ticker: "FTM", 
                                    rpcUrls: ['https://rpcapi.fantom.network/'], 
                                    blockExplorerUrls: ['https://ftmscan.com/']
                                }}
                            />
                        </Box>
                        <Box as="div">
                            <Box
                                as="div"
                                path="https://wallet.noku.io"
                                sx={styles.nokuMetamask}
                                onClick={() =>
                                    changeNetwork(888, "Nokuchain", 18, "NOKU", "NOKU", ["https://nokuchain-rpc.noku.io"], ["https://explorer.noku.io"], ["https://static.noku.io/assets/noku/coins/noku.svg"])
                                }
                            >
                                Add Nokuchain to Metamask
                            </Box>
                        </Box>
                    </Box>
                    <Box as="div" sx={styles.rightLogo}>
                        <Image sx={styles.image} src={roundLogo} />
                    </Box>
                    <Box as="div">
                        <Box
                            as="div"
                            path="https://wallet.noku.io"
                            sx={styles.nokuMetamask2}
                            onClick={() =>
                                changeNetwork(888, "Nokuchain", 18, "NOKU", "NOKU", ["https://nokuchain-rpc.noku.io"], ["https://explorer.noku.io"], ["https://static.noku.io/assets/noku/coins/noku.svg"])
                            }
                        >
                            Add Nokuchain to Metamask
                        </Box>
                    </Box>
                </Flex>
            </TransparentCard>
        </Box>
    );
}

const styles = {
    rowWrapper: {
        flexDirection: ["column", null, null, null, "row"],
    },
    rightLogo: {
        marginLeft: ["0", null, null, null, "auto"],
        alignSelf: ["center", null, null, null, "baseline"],
    },
    nokuText: {
        textAlign: ["center", null, null, null, "left"],
        title: {
            fontWeight: "900",
            fontSize: ["18px", "18px", "18px", "21.5px"],
        },
        description: {
            color: "rgba(255, 255, 255, 0.4)",
            fontWeight: "normal",
            fontSize: ["14.5px", "14.5px", "18px", "21.5px"],
        },
    },
    cardWrapper: {
        marginBottom: "40px",
        flexWrap: "wrap",
        color: "rgba(255,255,255,1)",
        background: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        borderRadius: "15px",
    },
    tokenInfoWrapper: {
        flexDirection: "column",
        display: "block",
        pl: ["auto"],
        fontSize: ["14.5px", "14.5px", "18px", "21.5px"],
        textAlign: "left",
        "@media screen and (max-width: 991px)": {
            display: "block",
            textAlign: "center",
            pl: ["0", "0", "0", "0"],
            marginTop: "23px",
        },
        "@media (min-width: 992px)": { marginLeft: "20px" },
    },
    titleCardWrapper: {
        mt: ["0", null, null, null, "25px"],
        mb: ["0", null, null, null, "10px"],
        fontWeight: 700,
        fontSize: ["30px", "30px", "47.5px"],
        textAlign: ["center", null, null, null, "left"],
    },
    addrWrapper: {
        "@media (min-width: 960px)": { paddingRight: "40px" },
        paddingRight: "0",
        display: "flex",
        flexDirection: "column",
        h3: {
            mb: 0,
        },
        h4: {
            mt: 0,
            fontWeight: 600,
        },
    },
    image: {
        maxWidth: "100%",
        height: "auto",
        display: "block",
        verticalAlign: "middle",
        aspectRatio: "auto 120/120",
        width: ["61px", "null", "null", "null", "113px"],
    },
    nokuMetamask: {
        mt: "30px",
        mb: "10px",
        fontSize: "18px",
        fontWeight: "bold",
        letterSpacing: "-0.16px",
        textDecoration: "none",
        borderRadius: "5px",
        color: "#ffffff",
        border: "2px solid #fff",
        padding: "6.5px 24px",
        cursor: "pointer",
        display: "inline-block",
        "@media (max-width: 991px)": {
            display: "none",
        },
    },
    nokuMetamask2: {
        mt: "30px",
        fontSize: "18px",
        fontWeight: "bold",
        letterSpacing: "-0.16px",
        textDecoration: "none",
        borderRadius: "5px",
        color: "#ffffff",
        border: "2px solid #fff",
        padding: "6.5px 24px",
        cursor: "pointer",
        display: "none",
        "@media (max-width: 991px)": {
            display: "inline-block",
        },
    },
};
