/** @jsx jsx */
import { jsx, Text, Image, Box } from "theme-ui";
import { FaLinkedinIn } from "react-icons/fa";
export default function PersonCard({
    avatar,
    name,
    position,
    linkedin,
}) {
    return (
        <Box as="div" sx={styles.card}>
            <Box as="div" sx={styles.avatarWrapper}>
                <Image sx={styles.avatar} src={avatar} />
            </Box>

            <Box as="div" sx={styles.textWrapper}>
                <Text as="div" sx={styles.name}>
                    {name}
                </Text>
                <Text as="div" sx={styles.position}>
                    {position}
                </Text>
                <Box as="a" href={linkedin} target="_blank" sx={styles.network}>
                    {linkedin && <FaLinkedinIn />}
                </Box>
            </Box>
        </Box>
    );
}

const styles = {
    avatarWrapper: {
        height: "150px",
        width: "150px",
        minWidth: "150px", 
        mr: "26px",
    },
    textWrapper: {
        mt: "27px",
        textAlign: "left",
        position: "relative",
    },
    name: {
        color: "#4446ed",
        fontWeight: "900",
        lineHeight: "19px",
    },
    position: { color: "#fff", lineHeight: "19px" },
    network: {
        color: "#666666",
        position: "absolute",
        bottom: "0",
        transition: "all .25s",
        "svg:first-of-type": {
            mr: "30px",
        },
        ":hover": {
            color: "#fff"
        }
    },
    avatar: { height: "100%", width: "100%", objectFit: "cover" },
    card: {
        display: "flex",
        width: ["100%", "100%", "100%", "40%", "40%", "31%"],
        mb: ["16px", "16px", "16px", "16px", "65px"],
        overflow: "hidden",
        borderRadius: "15px",
        border: "solid 1px",
        borderColor: "#4446ed",
        backgroundColor: "transparent",
        boxShadow:
            "0 0 #0000,0 0 #0000,0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06)",

        ":nth-child(3n)": {
            mr: 0
        }
    },
};
