import React from "react";
import { Box, Container } from "theme-ui";
import Statistics from "./statistics";

const Banner = () => {
    return (
        <Box as="section" id="banner" sx={styles.banner}>
            <Container sx={styles.container}>
                <Statistics />
            </Container>
        </Box>
    );
};

export default Banner;

const styles = {
    banner: {
        textAlign: "center",        
        pt: ["110px", null, null, null, "130px"],
    },
    container: {
        display: "flex",
        justifyContent: "center",
    }
};
