import React from "react";
import { Box, Container, Image } from "theme-ui";
import bannerDesk from "assets/banner/img-marketplace.jpg";
const ImageBanner = () => {
    return (
        <Box as="section" id="marketplace" sx={styles.banner}>
            <Container sx={styles.container}>
                <Box as="div" sx={styles.imageWrapper}>
                    <picture>
                        <Image
                            sx={styles.imageBanner}
                            src={bannerDesk}
                            alt="Cryptoheroes banner"
                        />
                        <Box sx={styles.positionText}>
                            <Box as="div" sx={styles.textOnBanner}>
                                New Noku NFT Marketplace 2.0
                            </Box>
                        </Box>
                        <Box sx={styles.positionButton}>
                            <Box sx={styles.menuFooter}>
                                <Box as="a" target="_blank" href="https://marketplace.noku.io/" sx={styles.outBtn} >
                                    Buy Now
                                </Box>
                            </Box>
                        </Box>
                    </picture>
                </Box>
            </Container>
        </Box>
    );
};

export default ImageBanner;

const styles = {
    banner: {
    },
    imageBanner: {
        width: "100%",
        height: ["486px", "486px", "100%"],
        objectFit: "cover",
        position: "relative",
        borderRadius: "15px",
    },
    container: {
        position: "relative",
        ".bannerIcon": {
            position: "absolute",
            display: ["none", null, null, null, "block"],
        },
    },
    outBtn: {
        color: "#fff",
        textDecoration: "none",
        fontWeight: "bold"
    },
    positionText: {
        position: "absolute",
        left: "111px",
        right: "111px",
        textAlign: "center",
        justifyContent: ["center", "left"],
        display: "flex",
        top: ["42%", "42%", "33%"],
    },
    textOnBanner: {        
        width: ["215px", "215px", "550px"],
        lineHeight: ["1.26", "1.26", "1.1" ],
        fontSize: ["28.5px", "34px", null, "71.5px"],
        color: "#fff",
        fontWeight: "900",
        textAlign: ["center", "left"],
    },
    positionButton: {
        position: "absolute",
        left: "111px",
        right: "111px",
        textAlign: "center",
        justifyContent: ["center", "left"],
        display: "flex",
        top: ["84%", "84%", "66%"],
    },
    menuFooter: {        
        background: "#4446ed",
        color: "#fff",
        borderRadius: "10px",
        width: ["160px", "160px", "291px"],
        height: ["45px", null, null, "81px"],
        flexDirection: "column",
        fontSize: ["16px", "24px", null, "30px"],
        fontWeight: "normal",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
    },    
};
