import React from "react";
import { Box, Container } from "theme-ui";
import Title from "../../components/title";
import ImageCard from "../../components/imageCard";
import marketCap from "assets/cards/logo_coinmarketcap.svg";
import cryptoRank from "assets/cards/logo_cryptorank.png";
import gecko from "assets/cards/logo-coingecko.png";
const FindUs = () => {
    return (
        <Box as="section" id="find-us" sx={styles.banner}>
            <Container>
                <Title text="Find us on" style={{ lineHeight: "1.83" }} />
                <Box as="div" sx={styles.cardsWrapper}>
                    <ImageCard
                        style={{ marginBottom: "10px" }}
                        src={marketCap}
                        href="https://coinmarketcap.com/currencies/noku/"
                    />
                    <ImageCard
                        style={{ marginBottom: "10px" }}
                        src={gecko}
                        href="https://www.coingecko.com/en/coins/noku"
                    />
                    <ImageCard
                        style={{ marginBottom: "10px" }}
                        src={cryptoRank}
                        href="https://cryptorank.io/price/noku"
                    />
                </Box>
            </Container>
        </Box>
    );
};

export default FindUs;

const styles = {
    cardsWrapper: {
        display: "flex",
        justifyContent: "space-evenly",
        flexDirection: [null, "column", "row", null, null],
        alignItems: "center",
        flexWrap: "wrap",
    },
    banner: {
        overflow: "hidden",
        textAlign: "center",
        pt: "16px",
    },
};
