import React from "react";
import { Box, Image, Text } from "theme-ui";
import black from "assets/nokuGames/black.png";

export default function NokuGameCard({ title, description, imageUrl, link }) {
    return (
        <Box as="div" sx={styles.wrapper}>
            {imageUrl ? (
                <Image sx={styles.image} src={imageUrl} />
            ) : (
                <Image sx={styles.image} src={black} />
            )}
            <Text as="div" sx={styles.title}>
                {title}
            </Text>
            <Text as="div" sx={styles.description}>
                {description}
            </Text>
            { 
                link && 
                <Box as="a" target="_blank" sx={styles.button} href={link}>Play now</Box>
            }
            { 
                ! link && 
                <Box sx={{...styles.button, ...styles.buttonDisabled}}>Play now</Box>
            }
        </Box>
    );
}

const styles = {
    wrapper: {
        width: "328.7px",
        mb: "20px",
        display: "inline-block",
        lineHeight: 1,
        fontSize: ["21.5px"],
        textAlign: ["center", "center"],
        mr: "10px",
        ":nth-child(4n)": {
            mr: 0
        }
    },
    title: {
        color: "white",
        textTransform: "uppercase",
        fontWeight: 900,
        marginTop: "31px",
        lineHeight: 1,
        marginBottom: ["14px"],        
    },
    description: {
        color: "#666",
    },
    image: {
        borderRadius: "15px",
    },
    button: {
        backgroundColor: "#ffc739",
        color: "#000",
        textDecoration: "none",
        width: "160px",
        height: "45px",
        fontSize: "20px",
        borderRadius: "10px",
        display: "block",
        margin: "auto",
        marginTop: "14px",
        fontWeight: "bold",
        transition: "all .25s",
        textAlign: "center",
        lineHeight: "45px",

        ":hover": {
            opacity: .75
        }
    },
    buttonDisabled: {
        opacity: .2,
        ":hover": {
            opacity: .2
        }
    }
};
