import React from "react";
import { Box, Container } from "theme-ui";
import ImageCard from "../../components/imageCard";
import youtube from "assets/cards/youtube.png";
import crypto from "assets/cards/cryptonomist.png";
import koin from "assets/partners/koinsquare-logo.png";
import poseidon from "assets/partners/poseidon-logo.png";
import nemesis from "assets/partners/thenemesis-logo.png";
import blockchain from "assets/partners/blockchain-space.png";
import beacon from "assets/partners/beacon-global.jpg";
import nfg from "assets/partners/nfg-logo.jpg";
import soli from "assets/partners/soli-logo.jpg";
import treedefi from "assets/partners/treedefi-logo.jpg";
import ugd from "assets/partners/ugd-logo.jpg";


import bg3 from "assets/bg-3-left.png";
const MediaAboutUs = () => {
    return (
        <Box as="section" id="media-about-us" sx={styles.banner} style={{
            background: `url(${bg3}) no-repeat`,
            backgroundPosition: "right center",
            backgroundSize: "contain",
        }}>
            <Container sx={{ marginBottom: "40px" }}>
                <Box
                    as="div"
                    sx={styles.media}
                >
                    Media
                </Box>
                <Box as="div" sx={styles.cardsWrapper}>
                    <ImageCard
                        src={crypto}
                        href="https://en.cryptonomist.ch/2021/09/11/crypto-heroes-blockchain-game-airdrops-nfts/"
                        style={{ marginBottom: "10px" }}
                    />
                    <ImageCard
                        src={youtube}
                        href="https://www.youtube.com/watch?v=iwQL8AdTEXk"
                        style={{ marginBottom: "10px" }}
                    />
                </Box>
            </Container>
            <Container id="partners">
                <Box
                    as="div"
                    sx={styles.partners}
                >
                    Partners
                </Box>
                <Box as="div" sx={styles.cardsWrapper}>
                    <ImageCard
                        src={koin}
                        href="https://koinsquare.com/"
                        style={{ marginBottom: "10px" }}
                    />
                    <ImageCard
                        src={crypto}
                        href="https://en.cryptonomist.ch/"
                        style={{ marginBottom: "10px" }}
                    />
                    <ImageCard
                        src={nemesis}
                        href="https://thenemesis.io/"
                        style={{ marginBottom: "10px" }}
                    />
                    <ImageCard
                        src={poseidon}
                        href="https://foundation.app/@Poseidonnftfund"
                        style={{ marginBottom: "10px" }}
                    />
                    <ImageCard
                        src={blockchain}
                        href="https://www.blockchainspace.asia/"
                        style={{ marginBottom: "10px" }}
                    />
                    <ImageCard
                        src={beacon}
                        href="https://www.beaconglobal.top/"
                        style={{ marginBottom: "10px" }}
                    />
                    <ImageCard
                        src={soli}
                        href="https://www.solischolar.com/"
                        style={{ marginBottom: "10px" }}
                    />
                    <ImageCard
                        src={nfg}
                        href="https://www.nonfungiblegang.io/"
                        style={{ marginBottom: "10px" }}
                    />
                    <ImageCard
                        src={treedefi}
                        href="https://www.treedefi.com"
                        style={{ marginBottom: "10px" }}
                    />
                    <ImageCard
                        src={ugd}
                        href="https://www.unitedgamers.gg"
                        style={{ marginBottom: "10px" }}
                    />
                </Box>
            </Container>
        </Box>
    );
};

export default MediaAboutUs;

const styles = {
    media: {
        color: "white",
        textTransform: "uppercase",
        textAlign: "center",
        marginBottom: "40px",
        fontSize: "2rem",
        fontWeight: "bold",
        "@media (max-width: 767px)": {
            marginBottom: "0",
        }
    },
    partners: {
        color: "white",
        textTransform: "uppercase",
        textAlign: "center",
        marginBottom: "40px",
        fontSize: "2rem",
        fontWeight: "bold",
        "@media (max-width: 767px)": {
            marginBottom: "0",
        }
    },
    cardsWrapper: {
        display: "flex",
        justifyContent: "space-evenly",
        flexDirection: [null, "column", "row", null, null],
        alignItems: "center",
        flexWrap: "wrap",
        margin: "auto",
        maxWidth: 1200
    },
    banner: {
        overflow: "hidden",
        textAlign: "center",
        pt: "20px",
        pb: "20px",
    },
};
