/** @jsx jsx */
import { jsx } from "theme-ui";
import { Box } from "theme-ui";
export default function Title({ text, style }) {
    return (
        <Box
            as="div"
            style={style}
            sx={{
                color: "white",
                textAlign: "center",
                textTransform: "uppercase",
                marginBottom: ["0px", "0px", "40px"],
                fontSize: "3.2rem",
                fontWeight: "bold",
                "@media(maxWidth: 767px)": {
                    marginBottom: "0",
                }
            }}
        >
            {text}
        </Box>
    );
}
