import React from "react";
import { Box, Image, Text } from "theme-ui";
import hand from "assets/ecosystem/noku-hand.svg";

const HandImage = () => {
    return (
        <Box as="div" sx={styles.boxContainer}>
            <Box sx={styles.circleWrapper}>
                <Image src={hand}></Image>
            </Box>
            <Text as="div" sx={styles.textTitle}>
                Ecosystem
            </Text>
        </Box>
    );
};

export default HandImage;

const styles = {
    circleWrapper: {
        display: "flex",
        justifyContent: "end",
        img : {
            maxWidth: "73%",
        }
    },
    textTitle: {
        fontFamily: "Lato",
        fontSize: ["18vw", "18vw", "18vw", "18vw", "18vw"],
        fontWeight: "900",
        fontStretch: "normal",
        color: "white",
        opacity: "0.05",
        WebkitTextStroke: "1px #fff",
        lineHeight: "1",
        letterSpacing: "1.43px",
        marginTop: ["-30px", null, null, null, null, "-160px"]
    },
    boxContainer: {
        mb: ["40px", "60px", "80px", "100px", "76px"],
    },
};
