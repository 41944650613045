import React from "react";
import { Box, Text, Container, Image, Flex } from "theme-ui";
import bodyImage from "assets/body/device_mockup.png";
import Title from "../../components/title";
import data from "../../cms/content/bannerMarketing.json";
import parse from "html-react-parser";

export default function BannerMarketing() {
    const { body, footer, title } = data;
    return (
        <Box as="section" id="banner-marketing" sx={styles.banner}>
            <Container sx={styles.container}>
                <Title text={title} style={{ textAlign: "left", textTransform: 'none', lineHeight: 1.17, "@media (maxWidth: 767px)": { marginBottom: "18px" } }} />
                <Flex sx={styles.reverseWrapper}>
                    <Box as="div" sx={styles.wrapper}>
                        <Image src={bodyImage} />
                    </Box>
                    <Box as="div" sx={styles.wrapper}>
                        <Text as="div" sx={styles.body}>
                            <Text as="div" sx={styles.line}>
                                {parse(body.replaceAll("\n", "<br>"))}
                            </Text>
                        </Text>
                        <Text as="div" sx={styles.footer}>
                            {footer}
                        </Text>
                    </Box>
                </Flex>
            </Container>
        </Box>
    );
}

const styles = {
    wrapper: {
        width: [null, null, null, "100%", "50%"],
        alignSelf: "center",
    },
    reverseWrapper: {
        flexDirection: ["column", null, null, null, "row-reverse"],
    },
    line: {
        mb: "20px",
    },
    container: { display: "flex", flexWrap: "wrap", pb: "50px" },
    body: {
        textAlign: "left",
        color: "#666",
        mb: "30px",
    },
    banner: {
        overflow: "hidden",
        backgroundSize: "cover",
        textAlign: "center",
        pt: ["50px", null, null, null, "130px"],
        pb: "60px",
        "@media (max-width: 767px)": { 
            pb: "0px" 
        }
    },
    footer: {
        color: "#666",
        fontWeight: 600,
        fontSize: "1.5rem",
        lineHeight: "2rem",
        letterSpacing: ".025em",
        textAlign: "left",
    },
};
