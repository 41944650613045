/** @jsx jsx */
import { jsx, Text, Box, Image } from "theme-ui";
import metamask from "assets/ecosystem/metamask-icon.svg";
import { FaRegCopy } from "react-icons/fa";

export default function Address({ addr, description, changeNetwork, params }) {

    const addToken = async () => {        
        await changeNetwork(
            params.chainId, 
            params.chainName, 
            params.decimals, 
            params.symbol, 
            params.ticker, 
            params.rpcUrls, 
            params.blockExplorerUrls, 
            params.iconUrls
        );
        console.log("Network changed")

        setTimeout(async function () {
            await window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                    address: addr,
                    symbol: "NOKU",
                    decimals: 18,
                    image: "https://static.noku.io/assets/noku/coins/noku.svg",
                    },
                },
            });
        }, 500);        
    }

    return (
        <Box as="div" sx={styles.addrContent}>
            <Box as="div" sx={styles.flex}>
                <Text as="div" sx={styles.addrContent.text}>
                    {addr}
                </Text>
                <Text as="div" sx={styles.addrContent.smallMobile}>
                    {description}
                </Text>
                <Box as="div" sx={styles.icons}>
                    <FaRegCopy
                        onClick={() => navigator.clipboard.writeText(addr)}
                        sx={styles.icon}
                    />                    
                    <Image onClick={addToken} sx={styles.metamask} src={metamask} />
                </Box>
            </Box>
            <Text as="div" sx={styles.addrContent.small}>
                {description}
            </Text>
            
        </Box>
    );
}

const styles = {
    flex: {
        display: "flex",
        flexDirection: ['column', null, null, null, null, "row"]
    },
    icons: {
        marginTop: ['10px', null, null, null, null, "0"],
        marginLeft: ['0', null, null, null, null, "20px"],
        marginRight: "auto",
        textAlign: "left",
        display: "flex",
    },
    icon: { 
        color: "white", 
        cursor: "pointer",
        fontSize: ['24px', null, null, null, null]
    },
    metamask: { 
        width: ['27px', null, null, null, null],
        marginLeft: "20px",
        cursor: "pointer",
    },
    addrContent: {
        display: ["block", null, null, null, "block"],
        flexDirection: ["column", null, null, null, "row"],
        alignItems: "center",
        lineHeight: "1.5rem",
        justifyContent: "space-between",
        position: "relative",
        marginBottom: "25px",
        marginTop: "10px",
        text: {
            textTransform: "uppercase",
            fontWeight: "900",
            textAlign: "left",
            width: "100%",
            wordWrap: "break-word",
            fontSize: ["14.5px", null, null, null, "21.5px"],
        },
        small: {
            lineHeight: "1.25rem",
            fontWeight: 400,
            color: "rgba(255, 255, 255, 0.4)",
            marginRight: "auto",
            textAlign: "left",
            marginTop: "10px",
            display: ['none', null, null, null, "block"]
        },   
        smallMobile: {
            lineHeight: "1.25rem",
            fontWeight: 400,
            color: "rgba(255, 255, 255, 0.4)",
            marginRight: "auto",
            textAlign: "left",
            marginTop: "10px", 
            display: ['block', null, null, null, "none"]
        },        
    },
};
