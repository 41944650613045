/** @jsx jsx */
import { jsx, Text, Flex, Box, Spinner } from "theme-ui";
import { useState, useEffect } from "react";
import ColoredCard from "components/coloredCard";
import axios from "axios";
const mapperKeyTitle = {
    currentPrice: "Current Price",
    totalSupply: "Total Supply",
    circulatingSupply: "Circulating Supply",
    marketCap: "Market Cap",
};
export default function Statistics() {
    const [infos, setInfos] = useState([]);
    const fetchData = async () => {
        const result = (await axios.get("/.netlify/functions/token")).data;
        setInfos(
            Object.entries(result).map(([key, value]) => ({
                key,
                value: value,
                title: mapperKeyTitle[key],
            }))
        );
    };
    useEffect(() => {
        fetchData();
    }, []);
    return (
        <ColoredCard>
            <Box as="div" sx={styles.wrapper}>
                {infos.length > 0 ? (
                    infos.map((info) => (
                        <Flex
                            key={"statistics-" + info.key}
                            sx={styles.rowWrapper}
                        >
                            <Text as="div" sx={styles.title}>
                                {info.title}:
                            </Text>
                            <Text as="div" sx={styles.subTitle}>
                                {" " + info.value}
                            </Text>
                        </Flex>
                    ))
                ) : (
                    <Spinner />
                )}
            </Box>
        </ColoredCard>
    );
}

const styles = {
    card: {
        width: ["100%", null, "100%"],
    },
    wrapper: {
        width: "100%",
        justifyContent: "space-evenly",
        flexWrap: "wrap",
        display: "flex",
        lineHeight: 1,   
    },
    rowWrapper: {
        flexDirection: ["column", null, null, null, "row"],
        width: ["140px", null, null, null, "auto"],
        marginBottom: ["12px", null, null, 0, 0],
        fontSize: ["14.5px", null, null, null, null, null, "21.5px"]
    },
    title: {
        color: "black",
        fontWeight: "bold",
        textAlign: "left",
        pr: "5px",
    },
    subTitle: {
        color: "black",
        textAlign: "left",
    },
};
